import { useEffect, useState } from "react";

export const useImageDimension = (source?: string) => {
  const [dimension, setDimension] = useState({ height: 0, width: 0 });

  useEffect(() => {
    if (!source) {
      setDimension({ height: 0, width: 0 });
      return;
    }

    const image = new Image();
    image.src = source;

    const loadHandler = () => {
      setDimension({ height: image.height, width: image.width });
    };
    const errorHandler = () => {
      setDimension({ height: 0, width: 0 });
    };

    image.addEventListener("load", loadHandler);
    image.addEventListener("error", errorHandler);

    return () => {
      image.removeEventListener("load", loadHandler);
      image.removeEventListener("error", errorHandler);
    };
  }, [source, setDimension]);

  return dimension;
};
